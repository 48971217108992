import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import BreadCrumbsBar from '../components/breadCrumbsBar';
import Pricing from '../components/pricing';
function PricingAndPlans() {
    return (
      <div>
        <Header />
        <BreadCrumbsBar />
        <Pricing />
        <Footer />
      </div>
    );
  }

 export default PricingAndPlans; 
  