import React from "react";


const Step4 = () => {

  return (
    <div className="mt-24 flex justify-center items-center">
      <div className="flex items-center justify-center">
      <div className="firework"></div>
      <div className="firework"></div>
      <div className="firework"></div>
      <div className="firework"></div>
      <div className="firework"></div>
        <div className="p-1 rounded shadow-lg bg-gradient-to-r from-red-500 via-red-300 to-red-700">
          <div className="flex flex-col items-center p-4 space-y-2 bg-white font-gothic">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-green-500 w-28 h-28"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-700">
              Thank You For Your Order !
            </h1>
            <p>We will get back to you soon</p>
            <a
              href="/"
              className="inline-flex items-center px-4 py-2 text-white bg-red-700 border border-red-700 rounded-full hover:bg-red-700 focus:outline-none focus:ring"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
              <span className="text-sm font-medium">Home</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
