import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Pricing() {
  const history = useHistory();
  
  const handleDownload = () => {
    // Replace the URL with the correct path to your PDF file
    const pdfUrl = "UC8.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "UC8.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-white sm:py-1 text-center">
      <div className="flex justify-center opacity-80 bg-black">
        <iframe
          src="UC8.pdf"
          className="w-full h-[70vh] "
          title="uc8 pricing and plans"
        />
      </div>
      <div className="flex justify-center mt-4 space-x-3">
        <button
          onClick={handleDownload}
          className="flex items-center justify-center w-44 h-10 rounded-full border border-solid border-red-700 bg-red-700 duration-300 hover:bg-transparent hover:text-red-700 hover:border-red-700 text-white"
        >
          Download PDF
        </button>
        <button
          onClick={() => history.push(`/order-process`)}
          className="flex items-center justify-center w-44 h-10 rounded-full border border-solid border-red-700 bg-red-700 hover:bg-transparent duration-300 hover:text-red-700 hover:border-red-700 text-white"
        >
          Order
        </button>
      </div>
    </div>
  );
}

export default Pricing;
